import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";

export const useRolEkle = () => {
    const { accessToken } = useGetToken();
    const rolEkle = async ({ rolIsmi }) => {
        fetch(`http://89.19.24.18:3001/Rol/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                rolIsmi: rolIsmi ? rolIsmi : "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("rol add cevap : ", data);
            });
    };

    return { rolEkle };
};

export const useRolGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const rolGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Rol/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { rolGuncelle };
};

export const useRolSil = (id) => {
    const { accessToken } = useGetToken();
    const rolSil = async () => {
        fetch(`http://89.19.24.18:3001/Rol/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("rol delete cevap : ", data);
            });
    };
    return { rolSil };
};

export const useRolleriGetir = () => {
    const { accessToken } = useGetToken();
    const [roller, setRoller] = useState([]);

    const rolleriGetir = async () => {
        fetch("http://89.19.24.18:3001/Rol", {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setRoller(data);
            });
        return () => roller;
    };

    useEffect(() => {
        rolleriGetir();
    }, []);

    return { roller };
};

export const useRolGetir = (id) => {
    const { accessToken } = useGetToken();
    const [rol, setRol] = useState([]);
    const rolGetir = async () => {
        if (id) {
            fetch(`http://89.19.24.18:3001/Rol/byId/${id}`, {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    console.log("useRol : ", data);
                    setRol(data);
                });
        }
        return () => rol;
    };

    useEffect(() => {
        rolGetir();
    }, []);

    return { rol };
};
