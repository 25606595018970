import { Form, Button, DatePicker, Input, message, notification, Popconfirm, Select, Space, Spin, Switch, Upload, Tooltip } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import { useYanginDolabiGetir, useYanginDolabiGuncelle, useYanginDolabiSil } from "../hooks/useYanginDolabi";
import KmyHeader from "./KmyHeader";
import "react-quill/dist/quill.snow.css";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";
import "dayjs/locale/tr.js";

const { TextArea } = Input;

function YanginDolabiGuncelle() {
    let { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isPending, setIsPending] = useState();
    const [errMessage, setErrMessage] = useState(null);
    const [api, contextHolder] = notification.useNotification();

    const yetkiler = [99999]; //auth.permissions;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(true);
    const [yandolID, setYandolID] = useState();

    const { yanginDolabi } = useYanginDolabiGetir(id);
    const { yanginDolabiGuncelle } = useYanginDolabiGuncelle(id);
    const { yanginDolabiSil } = useYanginDolabiSil(id);

    const yanginDolabiKaydetButon = (yetkiler.includes(10201) || yetkiler.includes(99999)) && (
        <Button type="primary" htmlType="submit" loading={isPending} danger={errMessage} key="chapterSaveButton">
            {t("genel.kaydet")}
        </Button>
    );

    const yanginDolabiSilButon = (yetkiler.includes(10202) || yetkiler.includes(99999)) && (
        <Popconfirm
            key="yanginDolabiSilOnay"
            placement="bottomRight"
            title={t("genel.eminMisin")}
            description={t("genel.silinecek")}
            okText={t("genel.sil")}
            cancelText={t("genel.vazgec")}
            onConfirm={() => {
                yanginDolabiSil(id);
                navigate(`/yanginDolabiGuncelle/${yandolID}`);
            }}
            style={{ width: 400 }}
        >
            <Button type="primary" danger key="yanginDolabiSilButon">
                {t("genel.sil")}
            </Button>
        </Popconfirm>
    );

    useEffect(() => {
        if (yanginDolabi) {
            form.setFieldsValue({
                demirbasNo: yanginDolabi.demirbasNo,
                icerik: yanginDolabi.icerik,
                uretici: yanginDolabi.uretici,
                uretimTarihi: yanginDolabi.uretimTarihi ? dayjs(yanginDolabi.uretimTarihi) : null,
                bina: yanginDolabi.bina,
                konum: yanginDolabi.konum,
                hortumBoyu: yanginDolabi.hortumBoyu,
                hortumTipi: yanginDolabi.hortumTipi,
                hortumUcu: yanginDolabi.hortumUcu,
                kontrolPeriyodu: yanginDolabi.kontrolPeriyodu,
                planlananKontrol: yanginDolabi.planlananKontrol ? dayjs(yanginDolabi.planlananKontrol) : null,
            });
            setYandolID(yanginDolabi.yandolID);
            setLoading(false);
            console.log("yandolID : ", yanginDolabi.problemliMi);
        }
    }, [yanginDolabi]);

    const onSubmit = (e) => {
        setIsPending(true);
        yanginDolabiGuncelle({ id, e });
        setIsPending(false);
        api["success"]({ message: t("genel.kaydedildi"), placement: "topRight" });
    };

    return loading ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Form
            form={form}
            disabled={!yetkiler.includes(10201) && !yetkiler.includes(99999)}
            autoComplete="off"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 14 }}
            onFinish={(values) => {
                onSubmit(values);
            }}
            onFinishFailed={(error) => {
                console.log("onFinishFailed : ", error);
            }}
        >
            <KmyHeader caller="yanginDolabiGuncelle" buttons={[yanginDolabiKaydetButon, yanginDolabiSilButon]} backURL="/yanginDolabiList" />
            {contextHolder}
            <Form.Item name="demirbasNo" label={t("yanginDolabi.demirbasNo")} style={{ paddingTop: 20 }}>
                <Input
                    placeholder={t("yanginDolabi.demirbasNoHint")}
                    status={yanginDolabi.problemliMi ? "error" : null}
                    addonAfter={yanginDolabi ? yanginDolabi.yandolID : null}
                />
            </Form.Item>
            <Form.Item name="icerik" label={t("yanginDolabi.icerik")}>
                <Input placeholder={t("yanginDolabi.icerikHint")} />
            </Form.Item>
            <Form.Item name="uretici" label={t("yanginDolabi.uretici")}>
                <Input placeholder={t("yanginDolabi.ureticiHint")} />
            </Form.Item>
            <Form.Item name="uretimTarihi" label={t("yanginDolabi.uretimTarihi")}>
                <DatePicker style={{ width: "100%" }} locale={locale} format={"YYYY/MM/DD"} placeholder={t("yanginDolabi.uretimTarihiHint")} />
            </Form.Item>
            <Form.Item name="bina" label={t("yanginDolabi.bina")}>
                <Input placeholder={t("yanginDolabi.binaHint")} />
            </Form.Item>
            <Form.Item name="konum" label={t("yanginDolabi.konum")}>
                <Input placeholder={t("yanginDolabi.konumHint")} />
            </Form.Item>
            <Form.Item name="hortumBoyu" label={t("yanginDolabi.hortumBoyu")}>
                <Input placeholder={t("yanginDolabi.hortumBoyuHint")} />
            </Form.Item>
            <Form.Item name="hortumTipi" label={t("yanginDolabi.hortumTipi")}>
                <Input placeholder={t("yanginDolabi.hortumTipiHint")} />
            </Form.Item>
            <Form.Item name="hortumUcu" label={t("yanginDolabi.hortumUcu")}>
                <Input placeholder={t("yanginDolabi.hortumUcuHint")} />
            </Form.Item>
            <Form.Item name="kontrolPeriyodu" label={t("yanginDolabi.kontrolPeriyodu")}>
                <Input placeholder={t("yanginDolabi.kontrolPeriyoduHint")} addonAfter={t("genel.ay")} />
            </Form.Item>
            <Form.Item name="planlananKontrol" label={t("yanginDolabi.planlananKontrol")}>
                <DatePicker style={{ width: "100%" }} locale={locale} format={"YYYY/MM/DD"} placeholder={t("yanginDolabi.planlananKontrolHint")} />
            </Form.Item>
        </Form>
    );
}

export default YanginDolabiGuncelle;
