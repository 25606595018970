import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";

export const useYanginDolabiEkle = () => {
    const { accessToken } = useGetToken();
    const yangindolabiEkle = async ({ konum, icerik }) => {
        fetch(`http://89.19.24.18:3001/YanginDolabi/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                konum: konum ? konum : "",
                icerik: icerik ? icerik : "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { yangindolabiEkle };
};

export const useYanginDolabiGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const yanginDolabiGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/YanginDolabi/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { yanginDolabiGuncelle };
};

export const useYanginDolabiSil = (id) => {
    const { accessToken } = useGetToken();
    const yangindolabiSil = async () => {
        fetch(`http://89.19.24.18:3001/YanginDolabi/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { yangindolabiSil };
};

export const useYanginDolaplariniGetir = () => {
    const { accessToken } = useGetToken();
    const [yanginDolaplari, setYanginDolaplari] = useState([]);

    const yanginDolaplariniGetir = async () => {
        fetch("http://89.19.24.18:3001/YanginDolabi", {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setYanginDolaplari(data);
            });
        return () => yanginDolaplari;
    };

    useEffect(() => {
        yanginDolaplariniGetir();
    }, []);

    return { yanginDolaplari };
};

export const useYanginDolabiGetir = (id) => {
    const { accessToken } = useGetToken();
    const [yanginDolabi, setYanginDolabi] = useState([]);
    const yanginDolabiGetir = async () => {
        if (id) {
            fetch(`http://89.19.24.18:3001/YanginDolabi/byId/${id}`, {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    setYanginDolabi(data);
                });
        }
        return () => yanginDolabi;
    };

    useEffect(() => {
        yanginDolabiGetir();
    }, []);

    return { yanginDolabi };
};
