import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";

export const useKisiFirmaYaz = () => {
    const { accessToken } = useGetToken();
    const kisiFirmaYaz = async ({ e }) => {
        console.log("kisiFirmaYaz : ", e);
        fetch(`http://89.19.24.18:3001/KisiFirma/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                kisiID: e.kisiID ? e.kisiID : "",
                firmaID: e.firmaID ? e.firmaID : "",
                roller: e.roller ? e.roller : "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("kisifirma yaz cevap : ", data);
            });
    };

    return { kisiFirmaYaz };
};

export const useKisiFirmaGetir = (id) => {
    const [kisiFirma, setKisiFirma] = useState([]);
    const { accessToken } = useGetToken();
    const kisiFirmaGetir = async () => {
        fetch(`http://89.19.24.18:3001/KisiFirma/byId/${id}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setKisiFirma(data);
            });

        return () => kisiFirma;
    };

    useEffect(() => {
        kisiFirmaGetir();
    }, []);

    return { kisiFirma };
};

export const useKisiFirmaRol = (e) => {
    const [kisiFirma, setKisiFirma] = useState([]);
    const { accessToken } = useGetToken();
    const kisiFirmaRol = async () => {
        fetch(`http://89.19.24.18:3001/KisiFirma/rol/${e.kisiID}/${e.firmaID}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setKisiFirma(data);
            });

        return () => kisiFirma;
    };

    useEffect(() => {
        kisiFirmaRol();
    }, []);

    return { kisiFirma };
};
