import { Button, Form, Input, Layout, List, notification, Space, Spin, Typography } from "antd";
import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetToken } from "../hooks/useGetToken";
import KmyHeader from "./KmyHeader";
const { Content } = Layout;
const { Text } = Typography;

function UetdsPlaka() {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { accessToken } = useGetToken();
    const [api, contextHolder] = notification.useNotification();
    const [isPending, setIsPending] = useState(false);
    const [belgeSonucKodu, setBelgeSonucKodu] = useState();
    const [muayeneSonucKodu, setMuayeneSonucKodu] = useState();
    const [firmaUnvan, setFirmaUnvan] = useState();
    const [belge, setBelge] = useState([]);

    const onSubmit = (e) => {
        setIsPending(true);
        setBelge([]);
        setBelgeSonucKodu();
        setMuayeneSonucKodu();
        fetch(`http://89.19.24.18:3001/ApiServis/uetdsPlakaBelge/${e["plaka"]}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("data : ", data);
                if (data) {
                    if (data.sonucKodu == "0") {
                        setFirmaUnvan(data.firmaUnvan);
                        form.setFieldsValue({
                            firmaUnvan: data.firmaUnvan,
                            belgeTuru: data.belgeTuru,
                            belgeNo: data.belgeNo,
                            belgeGecerlilikTarihi: data.belgeGecerlilikTarihi,
                            unetNo: data.unetNo,
                        });
                        console.log("fu : ", firmaUnvan);
                        setBelge(data);
                    }
                    setBelgeSonucKodu(data.sonucKodu);
                }

                fetch(`http://89.19.24.18:3001/ApiServis/uetdsPlakaMuayene/${e["plaka"]}`, {
                    headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
                })
                    .then(function (response) {
                        return response.json();
                    })
                    .then(function (data) {
                        console.log("data : ", data);
                        if (data) {
                            if (data.sonucKodu == "0") {
                                form.setFieldsValue({
                                    muayeneGecerlilikTarihi: data.muayeneGecerlilikTarihi,
                                });
                            }
                            setMuayeneSonucKodu(data.sonucKodu);

                            fetch(`http://89.19.24.18:3001/ApiServis/uetdsPlakaCeza/${e["plaka"]}`, {
                                headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
                            })
                                .then(function (response) {
                                    return response.json();
                                })
                                .then(function (data) {
                                    console.log("ceza sorgulama sonucu : ", e["plaka"], data);

                                    fetch(`http://89.19.24.18:3001/ApiServis/uetdsPlakaUyari/${e["plaka"]}`, {
                                        headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
                                    })
                                        .then(function (response) {
                                            return response.json();
                                        })
                                        .then(function (data) {
                                            console.log("Uyari sorgulama sonucu : ", e["plaka"], data);
                                        });
                                });
                        }
                    });
            });

        setIsPending(false);
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <Form
                    form={form}
                    autoComplete="off"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 12 }}
                    onFinish={(values) => {
                        onSubmit(values);
                    }}
                >
                    <KmyHeader caller="uetdsPlaka" backURL="/" />
                    {contextHolder}
                    <Form.Item name="plaka" label={t("arac.plaka")} style={{ marginTop: 40 }} required>
                        <Space.Compact style={{ width: "100%" }}>
                            <Input placeholder={t("arac.plaka")} maxLength={15} />
                            <Button type="primary" htmlType="submit" loading={isPending}>
                                Kontrol et
                            </Button>
                        </Space.Compact>
                    </Form.Item>

                    {belgeSonucKodu == "0" ? (
                        <>
                            <Form.Item name="firmaUnvan" label={t("uetds.firmaUnvan")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="belgeTuru" label={t("uetds.belgeTuru")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="belgeNo" label={t("uetds.belgeNo")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="belgeGecerlilikTarihi" label={t("uetds.belgeGecerlilikTarihi")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="unetNo" label={t("uetds.unetNo")}>
                                <Input readOnly />
                            </Form.Item>
                        </>
                    ) : (
                        <Space direction="horizontal" style={{ width: "100%", justifyContent: "center", color: "#aaa", marginBottom: 20 }}>
                            Bu ekran Ulaştırma Bakanlığının UETDS servisini kullanır. Girilen araç plakasına kayıtlı belgeler varsa listeler.
                        </Space>
                    )}
                    {muayeneSonucKodu == "0" ? (
                        <Form.Item name="muayeneGecerlilikTarihi" label={t("uetds.muayeneGecerlilikTarihi")}>
                            <Input readOnly />
                        </Form.Item>
                    ) : null}

                    {belgeSonucKodu == "35" ? (
                        <Space
                            direction="horizontal"
                            style={{
                                width: "100%",
                                justifyContent: "center",
                                color: "#F00",
                                padding: 20,
                            }}
                        >
                            {t("uetds.plakaBelgeYok")}
                        </Space>
                    ) : null}
                </Form>
            </Content>
        </Layout>
    );
}

export default UetdsPlaka;
