import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";

export const useMerdivenEkle = () => {
    const { accessToken } = useGetToken();
    const merdivenEkle = async ({ aciklama }) => {
        fetch(`http://89.19.24.18:3001/Merdiven/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                aciklama: aciklama ? aciklama : "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { merdivenEkle };
};

export const useMerdivenGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const merdivenGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Merdiven/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { merdivenGuncelle };
};

export const useMerdivenSil = (id) => {
    const { accessToken } = useGetToken();
    const merdivenSil = async () => {
        fetch(`http://89.19.24.18:3001/Merdiven/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { merdivenSil };
};

export const useMerdivenleriGetir = () => {
    const { accessToken } = useGetToken();
    const [merdivenler, setMerdivenler] = useState([]);

    const merdivenleriGetir = async () => {
        fetch("http://89.19.24.18:3001/Merdiven", {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setMerdivenler(data);
            });
        return () => merdivenler;
    };

    useEffect(() => {
        merdivenleriGetir();
    }, []);

    return { merdivenler };
};

export const useMerdivenGetir = (id) => {
    const { accessToken } = useGetToken();
    const [merdiven, setMerdiven] = useState([]);
    const merdivenGetir = async () => {
        if (id) {
            fetch(`http://89.19.24.18:3001/Merdiven/byId/${id}`, {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    setMerdiven(data);
                });
        }
        return () => merdiven;
    };

    useEffect(() => {
        merdivenGetir();
    }, []);

    return { merdiven };
};
