import { useEffect, useState } from "react";
import { useGetToken } from "./useGetToken";

export const useYanginTupuEkle = () => {
    const { accessToken } = useGetToken();
    const yangintupuEkle = async ({ konum, aciklama }) => {
        fetch(`http://89.19.24.18:3001/YanginTupu/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify({
                konum: konum ? konum : "",
                aciklama: aciklama ? aciklama : "",
            }),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("add cevap : ", data);
            });
    };

    return { yangintupuEkle };
};

export const useYanginTupuGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const yanginTupuGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/YanginTupu/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("update cevap : ", data);
            });
    };
    return { yanginTupuGuncelle };
};

export const useYanginTupuSil = (id) => {
    const { accessToken } = useGetToken();
    const yangintupuSil = async () => {
        fetch(`http://89.19.24.18:3001/YanginTupu/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("delete cevap : ", data);
            });
    };
    return { yangintupuSil };
};

export const useYanginTupleriniGetir = () => {
    const { accessToken } = useGetToken();
    const [yanginTupleri, setYanginTupleri] = useState([]);

    const yanginTupleriniGetir = async () => {
        fetch("http://89.19.24.18:3001/YanginTupu", {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setYanginTupleri(data);
            });
        return () => yanginTupleri;
    };

    useEffect(() => {
        yanginTupleriniGetir();
    }, []);

    return { yanginTupleri };
};

export const useYanginTupuGetir = (id) => {
    const { accessToken } = useGetToken();
    const [yanginTupu, setYanginTupu] = useState([]);
    const yanginTupuGetir = async () => {
        if (id) {
            fetch(`http://89.19.24.18:3001/YanginTupu/byId/${id}`, {
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            })
                .then(function (response) {
                    return response.json();
                })
                .then(function (data) {
                    setYanginTupu(data);
                });
        }
        return () => yanginTupu;
    };

    useEffect(() => {
        yanginTupuGetir();
    }, []);

    return { yanginTupu };
};
