import { Avatar, Button, Card, Image, Input, Layout, Modal, Row, Space, Spin, Table } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import YanginTupuEkle from "./YanginTupuEkle";
import { useYanginTupleriniGetir } from "../hooks/useYanginTupu";
import KmyHeader from "./KmyHeader";
const { Header, Content } = Layout;
const { Meta } = Card;

function YanginTupuList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const { yanginTupleri } = useYanginTupleriniGetir();

    const addButton = (
        <Button
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                setModalOpen(true);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );

    useEffect(() => {
        if (yanginTupleri) {
            setDataSource(yanginTupleri);
            setFilteredSource(yanginTupleri);
            setIsPending(false);
        }
    }, [yanginTupleri]);

    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const konum = item.konum ? item.konum.toLowerCase() : "";
                    const aciklama = item.aciklama ? item.aciklama.toLowerCase() : "";
                    const comparisonString = `${konum} ${aciklama}`;
                    return comparisonString.includes(searchText.toLowerCase());
                })
            );
        }
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="yanginTupuList" buttons={[addButton]} backURL="/" />
                <Input
                    placeholder={t("genel.ara")}
                    prefix={<SearchOutlined />}
                    key="yanginTupuAra"
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Row style={{ padding: 20 }}>
                    {filteredSource.map((p) => {
                        return (
                            <Card
                                hoverable
                                style={{ width: 300, margin: 5 }}
                                key={`/yanginTupu_${p.yantupID}`}
                                onClick={() => {
                                    navigate(`/yanginTupuGuncelle/${p.yantupID}`);
                                }}
                            >
                                <Meta title={p.konum} description={p.aciklama} />
                            </Card>
                        );
                    })}
                </Row>
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="yanginTupuEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <YanginTupuEkle />
                </Modal>
            </Content>
        </Layout>
    );
}

export default YanginTupuList;
