import { Avatar, Button, Card, Image, Input, Layout, Modal, Row, Space, Spin, Table } from "antd";
import { React, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SearchOutlined, EditOutlined } from "@ant-design/icons";
import KisiEkle from "./KisiEkle";
import { useKisileriGetir } from "../hooks/useKisi";
import KmyHeader from "./KmyHeader";
const { Header, Content } = Layout;
const { Meta } = Card;

function KisiList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [filteredSource, setFilteredSource] = useState([]);
    const [errMessage, setErrMessage] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const { kisiler } = useKisileriGetir();

    const addButton = (
        <Button
            type="primary"
            htmlType="submit"
            loading={isPending}
            danger={errMessage}
            size="small"
            onClick={() => {
                setModalOpen(true);
            }}
        >
            {t("genel.ekle")}
        </Button>
    );

    useEffect(() => {
        if (kisiler.length > 0) {
            setDataSource(kisiler);
            setFilteredSource(kisiler);
            setIsPending(false);
        }
    }, [kisiler]);

    const searchData = (searchText) => {
        setFilteredSource(dataSource);
        if (searchText) {
            setFilteredSource(
                dataSource.filter((item) => {
                    const isim = item.isim ? item.isim.toLowerCase() : "";
                    const soyad = item.soyad ? item.soyad.toLowerCase() : "";
                    const unvan = item.unvan ? item.unvan.toLowerCase() : "";
                    const TCno = item.TCno ? item.TCno.toLowerCase() : "";
                    const comparisonString = `${isim} ${soyad} ${TCno} ${unvan}`;
                    return comparisonString.includes(searchText.toLowerCase());
                })
            );
        }
    };

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="kisiList" buttons={[addButton]} backURL="/" />
                <Input
                    placeholder={t("genel.ara")}
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Row style={{ padding: 20 }}>
                    {filteredSource.map((p) => {
                        return (
                            <Card
                                hoverable
                                style={{ width: 300, margin: 5 }}
                                onClick={() => {
                                    navigate(`/kisiGuncelle/${p.kisiID}`);
                                }}
                            >
                                <Meta
                                    avatar={
                                        <Avatar
                                            src={p.fotoURL}
                                            style={{ marginLeft: -15, marginRight: -10, marginBottom: -15, width: 50, height: 50 }}
                                        />
                                    }
                                    title={`${p.isim} ${p.soyad}`}
                                    description={p.unvan}
                                />
                            </Card>
                        );
                    })}
                </Row>
                <Modal
                    key="modalForm"
                    open={modalOpen}
                    onOk={() => {
                        setModalOpen(false);
                    }}
                    onCancel={() => {
                        setModalOpen(false);
                    }}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                            style={{ marginRight: 20 }}
                        >
                            {t("genel.vazgec")}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            form="kisiEkleForm"
                            htmlType="submit"
                            onClick={() => {
                                setModalOpen(false);
                            }}
                        >
                            {t("genel.kaydet")}
                        </Button>,
                    ]}
                >
                    <KisiEkle />
                </Modal>
            </Content>
        </Layout>
    );
}

export default KisiList;
