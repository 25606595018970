import dgList from "../constant/dangerousGoods.json";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Avatar, Card, Image, Input, Layout, Row, Space, Spin, Table } from "antd";
import KmyHeader from "./KmyHeader";
const { Header, Content } = Layout;
const { Meta } = Card;

function TableA() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isPending, setIsPending] = useState(true);
    const [filteredSource, setFilteredSource] = useState([]);
    const [listOfDangerousGoods, setListOfDangerousGoods] = useState([]);

    useEffect(() => {
        setListOfDangerousGoods(dgList);
        setFilteredSource(dgList);
        setIsPending(false);
    }, []);

    const searchData = (searchText) => {
        setFilteredSource(listOfDangerousGoods);
        if (searchText) {
            setFilteredSource(
                listOfDangerousGoods.filter((item) => {
                    const unno = item.unNo ? item.unNo : "";
                    const desc = t(`dg.${item.un_id}`) ? t(`dg.${item.un_id}`).toLowerCase() : "";
                    const comparisonString = `${unno} ${desc}`;
                    return comparisonString.includes(searchText.toLowerCase());
                })
            );
        }
    };

    const columns = [
        {
            title: t("dg.unno"),
            dataIndex: "unNo",
            key: "un_id",
        },
        {
            title: t("dg.desc"),
            dataIndex: "un_id",
            key: "un_id",
            render: (p) => <>{t(`dg.${p}`)}</>,
        },
        {
            title: t("dg.class"),
            dataIndex: "class",
            key: "un_id",
            filters: [
                { text: "1", value: "1" },
                { text: "2", value: "2" },
                { text: "3", value: "3" },
                { text: "4.1", value: "4.1" },
                { text: "4.2", value: "4.2" },
                { text: "4.3", value: "4.3" },
                { text: "5.1", value: "5.1" },
                { text: "5.2", value: "5.2" },
                { text: "6.1", value: "6.1" },
                { text: "6.2", value: "6.2" },
                { text: "7", value: "7" },
                { text: "8", value: "8" },
                { text: "9", value: "9" },
            ],
            onFilter: (value, record) => record.class === value,
        },
        {
            title: t("dg.labels"),
            dataIndex: "labels",
            key: "un_id",
            filters: [
                { text: "1", value: "1" },
                { text: "1.4", value: "1.4" },
                { text: "1.5", value: "1.5" },
                { text: "1.6", value: "1.6" },
                { text: "2.1", value: "2.1" },
                { text: "2.2", value: "2.2" },
                { text: "2.3", value: "2.3" },
                { text: "3", value: "3" },
                { text: "4.1", value: "4.1" },
                { text: "4.2", value: "4.2" },
                { text: "4.3", value: "4.3" },
                { text: "5.1", value: "5.1" },
                { text: "5.2", value: "5.2" },
                { text: "6.1", value: "6.1" },
                { text: "6.2", value: "6.2" },
                { text: "7E", value: "7E" },
                { text: "7X", value: "7X" },
                { text: "8", value: "8" },
                { text: "9", value: "9" },
                { text: "9A", value: "9A" },
            ],
            onFilter: (value, record) => record.labels.indexOf(value) >= 0,
        },
        {
            title: t("dg.packingGroup"),
            dataIndex: "packingGroup",
            key: "un_id",
            filters: [
                { text: "I", value: "I" },
                { text: "II", value: "II" },
                { text: "III", value: "III" },
            ],
            onFilter: (value, record) => record.packingGroup === value,
        },
    ];

    return isPending ? (
        <div
            style={{
                display: "flex",
                position: "fixed",
                left: "0",
                right: "0",
                top: "0",
                bottom: "0",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Spin size="large" />
        </div>
    ) : (
        <Layout>
            <Content style={{ padding: "0px" }}>
                <KmyHeader caller="tableA" buttons={[]} backURL="/" />
                <Input
                    placeholder="search"
                    prefix={<SearchOutlined />}
                    style={{ margin: 10, width: 300 }}
                    onChange={(e) => {
                        searchData(e.target.value);
                    }}
                />
                <Table dataSource={filteredSource} columns={columns}></Table>
            </Content>
        </Layout>
    );
}

export default TableA;

/*
                <Row style={{ padding: 20 }}>
                    {filteredSource.map((p) => {
                        var etiket = "";
                        etiket = p.labels;
                        if (etiket) {
                            etiket = etiket.split(" ")[0];
                            etiket = etiket.replaceAll(".", "");
                            if (etiket.length == 1) {
                                etiket = `${etiket}0`;
                            }
                        }
                        return (
                            <Card
                                hoverable
                                style={{ width: 500, margin: 5 }}
                                onClick={() => {
                                    //navigate(`/kisiGuncelle/${p.kisiID}`);
                                }}
                            >
                                <Meta
                                    avatar={
                                        <Avatar
                                            src={`https://pro.kmysistem.com/images/adr${etiket}.png`}
                                            style={{ marginLeft: -20, marginRight: -10, width: 60, height: 60 }}
                                        />
                                    }
                                    title={`UN ${p.unNo}`}
                                    description={`${t(`dg.${p.un_id}`)} ${t("dg.class")}: ${p.class}`}
                                />
                                <>
                                    <b>{t("dg.classificationCode")}:</b> {p.classificationCode}&nbsp;
                                    {p.packingGroup == "" ? "" : `<b>${t("dg.packingGroup")}:</b> ${p.packingGroup}`}`
                                </>
                            </Card>
                        );
                    })}
                </Row>
*/

/*
    const columns = [
        {
            title: t("dg.unno"),
            dataIndex: "unNo",
            key: "un_id",
        },
        {
            title: "Description",
            dataIndex: "un_id",
            key: "un_id",
            render: (p) => <>{t(`dg.${p}`)}</>,
        },
        {
            title: "Class",
            dataIndex: "class",
            key: "un_id",
            filters: [
                { text: "1", value: "1" },
                { text: "2", value: "2" },
                { text: "3", value: "3" },
                { text: "4.1", value: "4.1" },
                { text: "4.2", value: "4.2" },
                { text: "4.3", value: "4.3" },
                { text: "5.1", value: "5.1" },
                { text: "5.2", value: "5.2" },
                { text: "6.1", value: "6.1" },
                { text: "6.2", value: "6.2" },
                { text: "7", value: "7" },
                { text: "8", value: "8" },
                { text: "9", value: "9" },
            ],
            onFilter: (value, record) => record.class === value,
        },
        {
            title: "Labels",
            dataIndex: "labels",
            key: "un_id",
            filters: [
                { text: "1", value: "1" },
                { text: "1.4", value: "1.4" },
                { text: "1.5", value: "1.5" },
                { text: "1.6", value: "1.6" },
                { text: "2.1", value: "2.1" },
                { text: "2.2", value: "2.2" },
                { text: "2.3", value: "2.3" },
                { text: "3", value: "3" },
                { text: "4.1", value: "4.1" },
                { text: "4.2", value: "4.2" },
                { text: "4.3", value: "4.3" },
                { text: "5.1", value: "5.1" },
                { text: "5.2", value: "5.2" },
                { text: "6.1", value: "6.1" },
                { text: "6.2", value: "6.2" },
                { text: "7E", value: "7E" },
                { text: "7X", value: "7X" },
                { text: "8", value: "8" },
                { text: "9", value: "9" },
                { text: "9A", value: "9A" },
            ],
            onFilter: (value, record) => record.labels.indexOf(value) >= 0,
        },
        {
            title: t("dg.packingGroup"),
            dataIndex: "packingGroup",
            key: "un_id",
            filters: [
                { text: "I", value: "I" },
                { text: "II", value: "II" },
                { text: "III", value: "III" },
            ],
            onFilter: (value, record) => record.packingGroup === value,
        },
    ];
*/
