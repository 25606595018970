import { useGetToken } from "./useGetToken";
import { useGetUserInfo } from "./useGetUserInfo";
import { useEffect, useState } from "react";

export const useTespitEkle = () => {
    const { accessToken } = useGetToken();
    const tespitEkle = async (e) => {
        fetch(`http://89.19.24.18:3001/Tespit/`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("tespit add cevap : ", data);
            });
    };

    return { tespitEkle };
};

export const useTespitGuncelle = (id, e) => {
    const { accessToken } = useGetToken();
    const tespitGuncelle = async ({ id, e }) => {
        fetch(`http://89.19.24.18:3001/Tespit/byId/${id}`, {
            method: "PUT",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
            body: JSON.stringify(e),
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("tespit update cevap : ", data);
            });
    };
    return { tespitGuncelle };
};

export const useTespitSil = (id) => {
    const { accessToken } = useGetToken();
    const tespitSil = async () => {
        fetch(`http://89.19.24.18:3001/Tespit/byId/${id}`, {
            method: "DELETE",
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                console.log("tespit delete cevap : ", data);
            });
    };
    return { tespitSil };
};

export const useTespitleriGetir = () => {
    const { accessToken } = useGetToken();
    const [tespitler, setTespitler] = useState([]);
    const tespitleriGetir = async () => {
        fetch("http://89.19.24.18:3001/Tespit", { headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` } })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setTespitler(data);
            });

        return () => tespitler;
    };
    useEffect(() => {
        tespitleriGetir();
    }, []);

    return { tespitler };
};

export const useTespitiGetir = (id) => {
    const { accessToken } = useGetToken();
    const [tespit, setTespit] = useState([]);
    const tespitiGetir = async () => {
        fetch(`http://89.19.24.18:3001/Tespit/byId/${id}`, {
            headers: { "Content-Type": "application/json", Authorization: `Bearer ${accessToken}` },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setTespit(data);
            });

        return () => tespit;
    };

    useEffect(() => {
        tespitiGetir();
    }, []);

    return { tespit };
};
